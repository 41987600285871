<template>
  <div class="flex min-h-screen flex-col">
    <Navbar class="flex-none" />

    <div class="grow">
      <slot />
    </div>

    <Footer class="flex-none" />
  </div>
</template>
