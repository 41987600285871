<script setup lang="ts">
interface FooterLink {
  path: string
  name: string
}

interface SocialIconLink {
  href: string
  label: string
  icon: string
}

const { t } = useI18n()

const links = computed((): FooterLink[] => [
  { path: '/contacts', name: t('contacts.header') },
  { path: '/privacy-policy', name: t('privacy_policy.header') },
  { path: '/security-info', name: t('security_info.header') },
  { path: '/terms-of-service', name: t('terms_of_service.header') },
])

const socialIconLinks = computed((): SocialIconLink[] => [
  {
    href: 'https://instagram.com/redexy_com/',
    label: 'Instagram',
    icon: 'simple-icons:instagram',
  },
  {
    href: 'https://facebook.com/redexycom',
    label: 'Facebook',
    icon: 'simple-icons:facebook',
  },
])

const copyright = computed(() => t('shared.components.footer.copyright'))
</script>

<template>
  <footer class="mb-6 mt-12">
    <div class="relative mx-auto flex max-w-full flex-col gap-6 px-4 md:max-w-5xl xl:max-w-7xl 2xl:max-w-[96rem]">
      <ul class="flex flex-wrap justify-center">
        <li
          v-for="link in links"
          :key="link.path"
          class="mx-2 whitespace-nowrap"
        >
          <NuxtLink
            :to="link.path"
          >
            {{ link.name }}
          </NuxtLink>
        </li>
      </ul>

      <Divider />

      <div class="flex justify-center gap-4">
        <a
          v-for="socialIconLink in socialIconLinks"
          :key="socialIconLink.href"
          :href="socialIconLink.href"
          :aria-label="socialIconLink.label"
          target="_blank"
          class="link"
        >
          <Icon
            :name="socialIconLink.icon"
            class="size-5"
          />
        </a>
      </div>

      <div class="text-center text-sm">
        {{ copyright }}
      </div>
    </div>
  </footer>
</template>
