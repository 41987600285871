<script setup lang="ts">
const colorMode = useColorMode()
const storage = useLocalStorage('color-mode', colorMode.value || 'dark')
const { t } = useI18n()

const handleThemeToggle = () => {
  const themeMap: Record<string, string> = {
    system: colorMode.value === 'dark' ? 'light' : 'dark',
    dark: 'light',
    light: 'dark',
  }
  const theme = themeMap[colorMode.preference as string]

  if (!theme) return

  colorMode.preference = theme
  storage.value = theme
}
</script>

<template>
  <a
    href=""
    class="flex items-center focus:rounded-lg focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-base-accent"
    @click.prevent="handleThemeToggle"
  >
    <ColorScheme tag="span">
      <div
        class="theme"
        aria-label="t('shared.components.navbar.color_scheme')"
        :class="colorMode.value"
      />{{ t('shared.components.navbar.color_scheme') }}
    </ColorScheme>
  </a>
</template>

<style scoped>
@reference '~/assets/css/main.css';

.theme {
  --size: 1.4rem;
  --ray-size: calc(var(--size) * -0.4);
  --offset-orthogonal: calc(var(--size) * 0.65);
  --offset-diagonal: calc(var(--size) * 0.45);

  @apply text-[#0ea5e9] mr-2;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  transition: all 500ms;

  &.light {
    transform: scale(0.75);
    box-shadow:
      inset 0 0 0 var(--size),
      calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
      var(--offset-orthogonal) 0 0 var(--ray-size),
      0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
      0 var(--offset-orthogonal) 0 var(--ray-size),
      calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
      var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
      calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
      var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
    @apply text-[#eab308];
  }
}
</style>
