<script setup lang="ts">
interface MenuItem {
  path: string
  name: string
}

const router = useRouter()
const { t } = useI18n()
const [isContextMenuShown, contextMenuToggle] = useToggle(false)
const [isMenuShown, menuToggle] = useToggle(false)

const authStore = useAuthStore()
const currentUserStore = useCurrentUserStore()
const { me: user } = storeToRefs(currentUserStore)
const isAuthenticated = useCookie('auth')

await useAsyncData('me', () => currentUserStore.fetch())

const menuItems = computed((): MenuItem[] => [
  { path: '/models', name: t('models.header') },
  { path: '/photographers', name: t('photographers.header') },
])

const contextMenuItems = computed((): ContextMenuEntry[] => [
  {
    type: 'item',
    name: 'profile',
    icon: 'mingcute:profile-line',
    title: t('shared.navigation.profile'),
    to: '/profile',
  },
  // {
  //   type: 'item',
  //   name: 'settings',
  //   icon: 'mingcute:settings-6-line',
  //   title: t('shared.navigation.settings'),
  //   to: '/settings',
  // },
  {
    type: 'slot',
  },
  {
    type: 'item',
    name: 'sign-out',
    icon: 'mingcute:exit-door-line',
    title: t('shared.navigation.sign_out'),
  },
])

const handleContextMenuClick = (item: ContextMenuItem) => {
  if (item.name === 'sign-out') authStore.signOut()
}

const handleSignIn = () => {
  router.push('/sign-in')
}
</script>

<template>
  <header class="header">
    <div class="relative mx-auto max-w-full px-4 md:max-w-5xl xl:max-w-7xl 2xl:max-w-[96rem]">
      <nav
        :aria-label="t('shared.components.navbar.main_menu')"
        class="navigation"
        role="navigation"
      >
        <Logo class="order-2 mr-14 md:order-none" />

        <BurgerButton
          v-model:state="isMenuShown"
          aria-label="t('shared.components.navbar.toggle_main_menu')"
          class="order-1 mr-4 block self-center md:order-none md:hidden"
        />

        <ul
          id="menu"
          role="menubar"
          aria-label="t('shared.components.navbar.open_page')"
          class="menu"
          :class="isMenuShown ? 'menu-shown' : 'menu-hidden'"
        >
          <li
            v-for="item in menuItems"
            :key="item.path"
            role="none"
            class="menu-item"
          >
            <NuxtLink
              :to="item.path"
              role="menuitem"
              aria-haspopup="false"
              tabindex="0"
              class="menu-link"
              @click="menuToggle(false)"
            >
              {{ item.name }}
            </NuxtLink>
          </li>
        </ul>

        <div class="relative order-3 flex grow items-center justify-end space-x-4 md:order-none">
          <LanguageSwitcher />

          <div
            v-if="isAuthenticated && user"
            class="flex items-center"
          >
            <button
              class="user-menu"
              @click.prevent="contextMenuToggle()"
            >
              <Avatar :user="user" />
            </button>

            <ContextMenu
              v-model="isContextMenuShown"
              :items="contextMenuItems"
              class="mt-16"
              @click="handleContextMenuClick"
            >
              <template #header>
                <p class="font-normal">
                  {{ user.email }}
                </p>
              </template>

              <template #slot>
                <ThemeSwitcher class="px-4 py-3" />
              </template>
            </ContextMenu>
          </div>

          <div v-else>
            <Button
              size="small"
              color="gray"
              @click.prevent="handleSignIn()"
            >
              {{ t('shared.components.navbar.sign_in') }}
            </Button>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<style scoped>
@reference '~/assets/css/main.css';

.header {
  @apply sticky top-0 z-20 w-full backdrop-blur border-b shadow-lg bg-navbar-bg border-navbar-border;
}

.navigation {
  @apply flex h-[4rem] items-stretch justify-between text-base-text;
}

.user-menu {
  @apply size-10 rounded-sm cursor-pointer bg-button-gray-bg overflow-hidden
    outline-2 outline-offset-1 outline-box-border
    hover:outline-box-border-light focus-visible:outline-base-accent;
}

.menu {
  @apply absolute left-0 right-0 top-0 z-[-1] flex md:items-center h-screen w-full flex-col overflow-hidden overflow-y-auto overscroll-contain
    pb-12 pt-[4.2rem] divide-y divide-navbar-border divide-dashed md:divide-none
    md:flex-row md:justify-center md:gap-2
    md:visible md:relative md:top-0 md:z-0 md:flex md:h-full md:w-auto md:overflow-visible
    md:bg-white/0 md:px-0 md:py-0 md:pt-0 md:opacity-100;
}

.menu-shown {
  @apply visible opacity-100 backdrop-blur-sm bg-navbar-bg-mobile;
}

.menu-hidden {
  @apply invisible opacity-0 md:visible md:opacity-100;
}

.menu-item {
  @apply px-2 md:p-0 py-3;
}

.menu-link {
  @apply flex w-full font-normal items-center rounded-lg px-3 h-10
    focus:outline-none focus-visible:outline-none
    text-navbar-link-text hover:text-navbar-link-text-hover
    bg-navbar-link-bg hover:bg-navbar-link-bg-hover focus-visible:bg-navbar-link-bg-hover;
}
</style>
